import React, {useState, useCallback, useEffect} from 'react';
import './work.css'
function Work() {

    return (
        <div>
            <div className="section2">
                <center>
                    <div className="container-fluid">
                        <div className="row">
                        <div className="col-md-6">
                                <div className="content">

                                    <div className="content-overlay"></div>
                                    <img src="/image/mylnk.gif"/>
                                    <div className="content-details fadeIn-bottom">
                                        <a href="https://mylnk.co.in/" target="_blank">
                                            <h3 className="content-title">Mylnk<br></br> (Self Project)</h3>
                                        </a>

                                    </div>

                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="content">

                                    <div className="content-overlay"></div>
                                    <img src="/image/justchat.png" height="226" />
                                    <div className="content-details fadeIn-bottom">
                                        <a href="https://justchat.priyonujdey.in/" target="_blank">
                                            <h3 className="content-title">justchat<br></br> (Self Project)</h3>
                                        </a>

                                    </div>

                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="content">

                                    <div className="content-overlay"></div>
                                    <img src="/image/makeyacv.gif"/>
                                    <div className="content-details fadeIn-bottom">
                                        <a href="http://makeyacv.priyonujdey.in" target="_blank">
                                            <h3 className="content-title">makeyacv <br></br>(Self Project)</h3>
                                        </a>

                                    </div>

                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="content">

                                    <div className="content-overlay"></div>
                                    <img src="/image/pubbs.gif"/>
                                    <div className="content-details fadeIn-bottom">
                                        <a href="https://pubbs.in/" target="_blank">
                                            <h3 className="content-title">Public Bicycle Sharing System (PUBBS)</h3>
                                        </a>

                                    </div>

                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="content">

                                    <div className="content-overlay"></div>
                                    <img src="/image/cfl.gif"/>
                                    <div className="content-details fadeIn-bottom">
                                        <a href="http://www.arp.iitkgp.ac.in/cfl/" target="_blank">
                                            <h3 className="content-title">City Future Lab, IIT KGP</h3>
                                        </a>

                                    </div>

                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="content">

                                    <div className="content-overlay"></div>
                                    <img src="/image/bus.gif"/>
                                    <div className="content-details fadeIn-bottom">
                                        <a href="https://pubbs.co.in/" target="_blank">
                                            <h3 className="content-title">PUBBS Transit</h3>
                                        </a>

                                    </div>

                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="content">

                                    <div className="content-overlay"></div>
                                    <img src="/image/blogsopt.gif"/>
                                    <div className="content-details fadeIn-bottom">
                                        <a href="https://priyonuj.blogspot.com/" target="_blank">
                                            <h3 className="content-title">My First Page</h3>
                                        </a>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                </center>
            </div>

        </div>
    )
}

export default Work;