import React, {useState, useCallback, useEffect} from 'react';
import firebase from 'firebase'

import 'firebase/database'
import config from '../firebasesdk/config'
import {useListVals, useList, useObjectVal} from 'react-firebase-hooks/database';
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();
var bgColor = 0;
function SocialLink(props) {
    bgColor = props.bgcolor;
    const [link,loadinglink ] = useListVals(db.ref('UserAccount/'+ process.env.REACT_APP_USER+'/Link/SocialLinks'));
    const [plink,ploadinglink ] = useListVals(db.ref('UserAccount/'+ process.env.REACT_APP_USER+'/Link/ProfessionalLinks'));
    return (
        <div>
         
          {link && link.map(msg => <SocialLinkList group={msg}/>)}
        
          {plink && plink.map(msg => <SocialLinkList group={msg}/>)}
          
        </div>
    )
}

function SocialLinkList(props) {
  const {media,url,} = props.group
    return(<><a href={url} target="_blank">
        
        <img src={bgColor ? media.replace('xyz', 'ffffff') : media.replace('xyz', '000000') }/>
        
        </a></>)
}
export default SocialLink;