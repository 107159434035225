import React, {useState} from 'react';
import "./divcss.css"
import firebase from 'firebase'
import 'firebase/database'
import config from '../firebasesdk/config'
import Education from '../Education/education'
import Project from '../Project/project'
import Experience from '../Experience/experience'
import Skill from '../Skill/skill'
import SocialLink from '../SocialLink/sociallink'
import Membership from '../Membership/membership'
import Award from '../Award/award'
import Work from '../Work/work'
import {useObjectVal} from 'react-firebase-hooks/database';
import Modal from 'react-modal';
import ReCAPTCHA from "react-google-recaptcha";
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();

function Maindiv() {

    const [darkLight,
        setdarkLight] = useState('0');
    const [openSection,
        setopenSection] = useState('0');
    const [Home,
        loading] = useObjectVal(db.ref('UserAccount/'+ process.env.REACT_APP_USER+'/AboutData'));
    //contact
    if(!loading && Home){
        document.getElementById('content_loading').style.display = 'none';
        document.getElementById('content_show').style.display = 'block';
    }
    const [externaluserName,
        setexternaluserName] = useState('');
    const [externaluserEmail,
        setexternaluserEmail] = useState('');
    const [externaluserSubject,
        setexternaluserSubject] = useState('');
    const [externaluserMessage,
        setexternaluserMessage] = useState('');
    const openCloseSection = e => {

        document
            .querySelector(".sidenavleft")
            .style
            .width = "0%";
        document
            .querySelector(".sidenavright")
            .style
            .width = "0%";
        setopenSection(e)

    }
    const openSectionRes = e => {
        //setopenSection(e)
        document
            .querySelector(".sidenavright")
            .style
            .width = "100%";

    }
    const CloseSectionRes = e => {
        //setopenSection(e)
        document
            .querySelector(".sidenavright")
            .style
            .width = "0%";
    }
    const closeCloseSection = e => {

        document
            .querySelector(".sidenavleft")
            .style
            .width = "50%";
        document
            .querySelector(".sidenavright")
            .style
            .width = "50%";

    }
    const openNavres = e => {
        document
            .querySelector(".sidenavleft")
            .style
            .width = "100%";
        document
            .querySelector(".sidenavright")
            .style
            .width = "100%";
        //  document.querySelector(".sidenavright").style.width = "100%";
    }
    const openNavresContact = e => {
        document
            .querySelector(".sidenavleft")
            .style
            .width = "100%";
        document
            .querySelector(".sidenavright")
            .style
            .width = "0";
        //  document.querySelector(".sidenavright").style.width = "100%";
    }

    
    const displaySection = (e) => {
        if (openSection == e) 
            return 'block';
        return 'none';
    }

    const [modalIsOpen,
        setIsOpen] = useState(false);
       
    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }
    const sendMessage = e => {
        if(externaluserName === '' || externaluserEmail === '' || externaluserSubject === '' || externaluserMessage === ''){
            alert("Input field caanot empty!.... error");
            return;
        }
        setIsOpen(true);
    }
    function onChange(value) {
        var today = new Date();
        var currentdate = new Date().toString('dd-MM-yyyy');
        var currenttime = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      if(externaluserName === '' || externaluserEmail === '' || externaluserSubject === '' || externaluserMessage === ''){
          alert("Input field caanot empty!.... error");
          return;
      }
        var messageInsert = db.ref().child('UserAccount/'+ process.env.REACT_APP_USER+'/Message');
        messageInsert.push().set({
          "Name": externaluserName,
          "Email":externaluserEmail,
          "Subject": externaluserSubject,
          "Message": externaluserMessage,
          "Date": currentdate,
          "Time": currenttime,
          "bgcolor": 'transparent'

        })
        var messageInsertNotification = db.ref().child('UserAccount/'+ process.env.REACT_APP_USER+'/Notification');
        messageInsertNotification.child('Message').push().update({
          "Name": externaluserName,
          "Date": currentdate,
          "Time": currenttime,
          "bgcolor": 'transparent'
        })

        var log = db.ref().child('UserAccount/'+ process.env.REACT_APP_USER+'/ActivityLog')
        log.push().update({
          "Date": currentdate,
          "Time": currenttime,
          "Message": 'New Message'
        })
        
        alert("Message Sent")
        setexternaluserName('')
        setexternaluserEmail(''); 
        setexternaluserSubject('')
        setexternaluserMessage('')

        setTimeout(() => {
            setIsOpen(false);   
        }, 500);
        
    }
    const [current_year,set_current_year] = useState(new Date().getFullYear())
  
    return (
        <div className="animate__animated animate__fadeIn">
            <div
                className="sidenav sidenavleft"
                style={{
                backgroundColor: darkLight
                    ? '#111'
                    : '#fffafa '
            }}>

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path
                        fill={darkLight
                        ? '#0c1445'
                        : '#d2e7ff '}
                        fill-opacity="0.5"
                        d="M0,224L26.7,213.3C53.3,203,107,181,160,186.7C213.3,192,267,224,320,213.3C373.3,203,427,149,480,133.3C533.3,117,587,139,640,149.3C693.3,160,747,160,800,160C853.3,160,907,160,960,165.3C1013.3,171,1067,181,1120,165.3C1173.3,149,1227,107,1280,101.3C1333.3,96,1387,128,1413,144L1440,160L1440,0L1413.3,0C1386.7,0,1333,0,1280,0C1226.7,0,1173,0,1120,0C1066.7,0,1013,0,960,0C906.7,0,853,0,800,0C746.7,0,693,0,640,0C586.7,0,533,0,480,0C426.7,0,373,0,320,0C266.7,0,213,0,160,0C106.7,0,53,0,27,0L0,0Z"></path>

                </svg>

                <div className="darkLight">
                    {darkLight
                        ? <span
                                className="material-icons"
                                style={{
                                color: 'white'
                            }}
                                onClick={e => setdarkLight(0)}>
                                wb_sunny
                            </span>
                        : <span
                            class="material-icons"
                            style={{
                            color: 'black'
                        }}
                            onClick={e => setdarkLight(1)}>dark_mode</span>}
                </div>

                <button className="btnopenNav btn btn-default" onClick={e => openSectionRes()}>
                    {darkLight
                        ? <span
                                className="glyphicon glyphicon-align-justify"
                                style={{
                                color: 'white'
                            }}></span>
                        : <span
                            className="glyphicon glyphicon-align-justify"
                            style={{
                            color: 'black'
                        }}></span>}

                </button>
                <div id="sidenavleftContent">

                    <center><img src="/image/Group 16.svg" className="bgImgLeft" alt="img"/>
                        <p
                            style={{
                            color: darkLight
                                ? 'white'
                                : 'black'
                        }}>Hello I'M</p>
                        <div className="container-fluid leftContent">
                            <div className="col-md-12">
                                <div id="content_loading"> 
                                   <img src="/image/loading.gif" />
                                </div>
                                <div id="content_show"> 
                                <div
                                    id="homeDetshow"
                                    style={{
                                    color: darkLight
                                        ? 'white'
                                        : 'black'
                                }}>
                                   {Home ? <p>{Home.name}</p>:<></>}
                                   {Home ?<p>{Home.description}</p>:<></>}
                                </div>
                                </div>
                               
                            </div>
                            <div className="col-md-12" id="contactLink">
                                <nav id="socialMediaPreview"><SocialLink bgcolor={darkLight}/></nav>
                            </div>
                            <div className="col-md-12">
                                
                                <button
                                    className="btn btn-contactme"
                                    onClick={e => openCloseSection(8)}
                                    style={{
                                    color: darkLight
                                        ? 'black'
                                        : 'white',
                                    backgroundColor: darkLight
                                        ? 'white'
                                        : '#111'
                                }}>
                                    Contact Me
                                </button>
                                
                            </div>

                        </div>

                    </center>
                    
                </div>
             
                <div className="seconfooter">
                       <p style={{
                            color: darkLight
                                ? 'white'
                                : '#111'
                        }}>&copy; PRIYONUJ DEY - {current_year}</p> 
                </div>
                
                
            </div>

            <div
                className="sidenav sidenavright"
                style={{
                backgroundColor: darkLight
                    ? '#111'
                    : 'white'
            }}>
                <button
                    className="btnopenNav btn btn-defaultb btn-resclose"
                    onClick={e => CloseSectionRes()}>

                    {darkLight
                        ? <span
                                className="glyphicon glyphicon-remove"
                                style={{
                                color: 'white'
                            }}></span>
                        : <span
                            className="glyphicon glyphicon-remove"
                            style={{
                            color: 'black'
                        }}></span>}

                </button>
                <div id="sidenavrightContent">

                    <div id="aboutmenu" className="menuContent" onClick={e => openCloseSection(1)}>
                        <div className="bgImg bgImg1">

                            {darkLight
                                ? <img src="/image/bgabout.png" alt="img"/>
                                : <img src="/image/bgaboutwhite.png" alt="img"/>}

                        </div>
                        <p
                            style={{
                            color: darkLight
                                ? 'white'
                                : '#111'
                        }}>About</p>
                    </div>
                    <div id="edmenu" className="menuContent" onClick={e => openCloseSection(2)}>
                        <div className="bgImg bgImg1">
                            {darkLight
                                ? <img src="/image/bgedu1.png" alt="img"/>
                                : <img src="/image/bgedu1white.png" alt="img"/>}

                        </div>
                        <p
                            style={{
                            color: darkLight
                                ? 'white'
                                : '#111'
                        }}>Education</p>
                    </div>
                    <div
                        id="Projectmenu"
                        className="menuContent"
                        onClick={e => openCloseSection(3)}>
                        <div className="bgImg bgImg1">
                            {darkLight
                                ? <img src="/image/bgedu.png"/>
                                : <img src="/image/bgprojectwhite.png"/>}

                        </div>
                        <p
                            style={{
                            color: darkLight
                                ? 'white'
                                : '#111'
                        }}>Project</p>
                    </div>
                    <div
                        id="Professionalmenu"
                        className="menuContent"
                        onClick={e => openCloseSection(9)}>
                        <div className="bgImg bgImg1">
                            {darkLight
                                ? <img src="/image/Group 48.jpg"/>
                                : <img src="/image/Group 47.jpg"/>
}

                        </div>
                        <p
                            style={{
                            color: darkLight
                                ? 'white'
                                : '#111'
                        }}>Work</p>
                    </div>
                    <div id="expmenu" className="menuContent" onClick={e => openCloseSection(4)}>
                        <div className="bgImg bgImg1">
                            {darkLight
                                ? <img src="/image/Group 31.png"/>
                                : <img src="/image/Group 39white.png"/>
}
                        </div>
                        <p
                            style={{
                            color: darkLight
                                ? 'white'
                                : '#111'
                        }}>Work Experience</p>
                    </div>
                    <div
                        id="Projectmenu"
                        className="menuContent"
                        onClick={e => openCloseSection(5)}>
                        <div className="bgImg bgImg1">
                            {darkLight
                                ? <img src="/image/Group 49.jpg"/>
                                : <img src="/image/bgskillwhite.jpg"/>}

                        </div>
                        <p
                            style={{
                            color: darkLight
                                ? 'white'
                                : '#111'
                        }}>Skill</p>
                    </div>
                    <div
                        id="Achivementmenu"
                        className="menuContent"
                        onClick={e => openCloseSection(6)}>
                        <div className="bgImg bgImg1">
                            {darkLight
                                ? <img src="/image/bgaward.png"/>
                                : <img src="/image/bgawardwhite.png"/>
}

                        </div>
                        <p
                            style={{
                            color: darkLight
                                ? 'white'
                                : '#111'
                        }}>Award
                        </p>
                    </div>
                    <div
                        id="Professionalmenu"
                        className="menuContent"
                        onClick={e => openCloseSection(7)}>
                        <div className="bgImg bgImg1">
                            {darkLight
                                ? <img src="/image/Group 48.jpg"/>
                                : <img src="/image/Group 47.jpg"/>
}

                        </div>
                        <p
                            style={{
                            color: darkLight
                                ? 'white'
                                : '#111'
                        }}>Professional Membership</p>
                    </div>

                </div>

            </div>

            <div id="contentmain">

                <div
                    id="about"
                    style={{
                    display: displaySection(1)
                }}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-5 firsta">
                                <button onClick={e => openNavres()} className="btnbackNav btn btn-default ">

                                    {darkLight
                                        ? <span
                                                className="glyphicon glyphicon-remove"
                                                style={{
                                                color: 'white'
                                            }}></span>
                                        : <span
                                            className="glyphicon glyphicon-remove"
                                            style={{
                                            color: 'black'
                                        }}></span>}
                                </button>
                                <div className="bgImg">
                                    <span className="backImg">
                                        {darkLight
                                            ? <img src="/image/Group 45-min.png"/>
                                            : <img src="/image/Group 46-min.png"/>
}
                                    </span>

                                </div>
                                <h1
                                    style={{
                                    color: darkLight
                                        ? 'white'
                                        : '#111'
                                }}>About Me</h1>
                            </div>
                            <div className="col-sm-7 seconda">
                                <button onClick={e => closeCloseSection()} className="btnclose btn btn-default">X</button>
                                <center>
                                <div id="homeDetImageshow">{Home ?<img src={Home.image} alt={Home.name}/>:<></>}</div>
                            </center>
                                <p></p>
                                <p id="nametemp4">{Home
                                            ? Home.aboutmedetail
                                            : <></>}</p>
                                <div className="carreo"></div>

                            </div>
                        </div>
                    </div>
                </div>
                <div
                    id="education"
                    style={{
                    display: displaySection(2)
                }}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-5 firsta">
                                <button onClick={e => openNavres()} className="btnbackNav btn btn-default ">
                                    {darkLight
                                        ? <span
                                                className="glyphicon glyphicon-remove"
                                                style={{
                                                color: 'white'
                                            }}></span>
                                        : <span
                                            className="glyphicon glyphicon-remove"
                                            style={{
                                            color: 'black'
                                        }}></span>}
                                </button>
                                <div className="bgImg">
                                    {darkLight
                                        ? <img src="/image/Group 45-min.png"/>
                                        : <img src="/image/Group 46-min.png"/>
}
                                </div>
                                <h1
                                    style={{
                                    color: darkLight
                                        ? 'white'
                                        : '#111'
                                }}>Education</h1>
                            </div>
                            <div className="col-sm-7 seconda">
                                <button onClick={e => closeCloseSection()} className="btnclose btn btn-default">X</button>

                                <Education/>

                            </div>
                        </div>
                    </div>
                </div>
                <div
                    id="Project"
                    style={{
                    display: displaySection(3)
                }}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-5 firsta">
                                <button onClick={e => openNavres()} className="btnbackNav btn btn-default ">
                                    {darkLight
                                        ? <span
                                                className="glyphicon glyphicon-remove"
                                                style={{
                                                color: 'white'
                                            }}></span>
                                        : <span
                                            className="glyphicon glyphicon-remove"
                                            style={{
                                            color: 'black'
                                        }}></span>}
                                </button>
                                <div className="bgImg">
                                    {darkLight
                                        ? <img src="/image/Group 45-min.png"/>
                                        : <img src="/image/Group 46-min.png"/>
}
                                </div>
                                <h1
                                    style={{
                                    color: darkLight
                                        ? 'white'
                                        : '#111'
                                }}>Project</h1>
                            </div>
                            <div className="col-sm-7 seconda">
                                <button onClick={e => closeCloseSection()} className="btnclose btn btn-default">X</button>
                                <Project/>

                            </div>
                        </div>
                    </div>
                </div>
                <div
                    id="Work"
                    style={{
                    display: displaySection(9)
                }}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-5 firsta">
                                <button onClick={e => openNavres()} className="btnbackNav btn btn-default ">
                                    {darkLight
                                        ? <span
                                                className="glyphicon glyphicon-remove"
                                                style={{
                                                color: 'white'
                                            }}></span>
                                        : <span
                                            className="glyphicon glyphicon-remove"
                                            style={{
                                            color: 'black'
                                        }}></span>}
                                </button>
                                <div className="bgImg">
                                    {darkLight
                                        ? <img src="/image/Group 45-min.png"/>
                                        : <img src="/image/Group 46-min.png"/>
}
                                </div>
                                <h1
                                    style={{
                                    color: darkLight
                                        ? 'white'
                                        : '#111'
                                }}>Work</h1>
                            </div>
                            <div className="col-sm-7 seconda">
                                <button onClick={e => closeCloseSection()} className="btnclose btn btn-default">X</button>
                                <Work/>

                            </div>
                        </div>
                    </div>
                </div>
                <div
                    id="experience"
                    style={{
                    display: displaySection(4)
                }}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-5 firsta">
                                <button onClick={e => openNavres()} className="btnbackNav btn btn-default ">
                                    {darkLight
                                        ? <span
                                                className="glyphicon glyphicon-remove"
                                                style={{
                                                color: 'white'
                                            }}></span>
                                        : <span
                                            className="glyphicon glyphicon-remove"
                                            style={{
                                            color: 'black'
                                        }}></span>}
                                </button>
                                <div className="bgImg">
                                    {darkLight
                                        ? <img src="/image/Group 45-min.png"/>
                                        : <img src="/image/Group 46-min.png"/>
}
                                </div>
                                <h1
                                    style={{
                                    color: darkLight
                                        ? 'white'
                                        : '#111'
                                }}>Work Experience</h1>
                            </div>
                            <div className="col-sm-7 seconda">
                                <button onClick={e => closeCloseSection()} className="btnclose btn btn-default">X</button>
                                <Experience/>

                            </div>
                        </div>
                    </div>
                </div>

                <div
                    id="skill"
                    style={{
                    display: displaySection(5)
                }}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-5 firsta">
                                <button onClick={e => openNavres()} className="btnbackNav btn btn-default ">
                                    {darkLight
                                        ? <span
                                                className="glyphicon glyphicon-remove"
                                                style={{
                                                color: 'white'
                                            }}></span>
                                        : <span
                                            className="glyphicon glyphicon-remove"
                                            style={{
                                            color: 'black'
                                        }}></span>}
                                </button>
                                <div className="bgImg">
                                    {darkLight
                                        ? <img src="/image/Group 45-min.png"/>
                                        : <img src="/image/Group 46-min.png"/>
}
                                </div>
                                <h1
                                    style={{
                                    color: darkLight
                                        ? 'white'
                                        : '#111'
                                }}>Skill</h1>
                            </div>
                            <div className="col-sm-7 seconda">
                                <button onClick={e => closeCloseSection()} className="btnclose btn btn-default">X</button>
                                <Skill/>

                            </div>
                        </div>
                    </div>
                </div>
                <div
                    id="Achivement"
                    style={{
                    display: displaySection(6)
                }}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-5 firsta">
                                <button onClick={e => openNavres()} className="btnbackNav btn btn-default ">
                                    {darkLight
                                        ? <span
                                                className="glyphicon glyphicon-remove"
                                                style={{
                                                color: 'white'
                                            }}></span>
                                        : <span
                                            className="glyphicon glyphicon-remove"
                                            style={{
                                            color: 'black'
                                        }}></span>}
                                </button>
                                <div className="bgImg">
                                    {darkLight
                                        ? <img src="/image/Group 45-min.png"/>
                                        : <img src="/image/Group 46-min.png"/>
}
                                </div>
                                <h1
                                    style={{
                                    color: darkLight
                                        ? 'white'
                                        : '#111'
                                }}>Award
                                </h1>
                            </div>
                            <div className="col-sm-7 seconda">
                                <button onClick={e => closeCloseSection()} className="btnclose btn btn-default">X</button>
                                <Award/>

                            </div>
                        </div>
                    </div>
                </div>
                <div
                    id="Professional"
                    style={{
                    display: displaySection(7)
                }}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-5 firsta">
                                <button onClick={e => openNavres()} className="btnbackNav btn btn-default ">
                                    {darkLight
                                        ? <span
                                                className="glyphicon glyphicon-remove"
                                                style={{
                                                color: 'white'
                                            }}></span>
                                        : <span
                                            className="glyphicon glyphicon-remove"
                                            style={{
                                            color: 'black'
                                        }}></span>}
                                </button>
                                <div className="bgImg">
                                    {darkLight
                                        ? <img src="/image/Group 45-min.png"/>
                                        : <img src="/image/Group 46-min.png"/>
}
                                </div>
                                <h1
                                    style={{
                                    color: darkLight
                                        ? 'white'
                                        : '#111'
                                }}>Professional Membership</h1>
                            </div>
                            <div className="col-sm-7 seconda">
                                <button onClick={e => closeCloseSection()} className="btnclose btn btn-default">X</button>

                                <Membership/>

                            </div>
                        </div>
                    </div>
                </div>
           
                <div
                    id="contact"
                    style={{
                    display: displaySection(8)
                }}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-5 firsta">
                                <button onClick={e => openNavresContact()} className="btnbackNav btn btn-default ">
                                    {darkLight
                                        ? <span
                                                className="glyphicon glyphicon-remove"
                                                style={{
                                                color: 'white'
                                            }}></span>
                                        : <span
                                            className="glyphicon glyphicon-remove"
                                            style={{
                                            color: 'black'
                                        }}></span>}
                                </button>
                                <div className="bgImg">
                                    {darkLight
                                        ? <img src="/image/Group 45-min.png"/>
                                        : <img src="/image/Group 46-min.png"/>
}
                                </div>
                                <h1
                                    style={{
                                    color: darkLight
                                        ? 'white'
                                        : '#111'
                                }}>Contact Me</h1>
                            </div>
                            <div className="col-sm-7 seconda">
                                <button onClick={e => closeCloseSection()} className="btnclose btn btn-default">X</button>
                                <div id="contactPreview">
                                    <br/><br/><br/>
                                    <div className="selfcontactbox">
                                        <div className="contactEmail">
                                            <span class="material-icons">
                                                email
                                            </span>
                                            <br></br>
                                            priyonuj@gmail.com
                                        </div>
                                        <div className="contactEmail">
                                            <span class="material-icons">
                                                location_on
                                            </span>
                                            <br></br>
                                            Kolkata , India
                                        </div>

                                    </div>

                                    <div className="login_form" id="login_section">

                                        <div className="container-fluid">
                                            <div className="col-sm-6">
                                                <div className="form__group field" id="email">
                                                    <input
                                                        type="input"
                                                        className="form__field"
                                                        placeholder="Name"
                                                        name="userEmail"
                                                        id='outsidername3'
                                                        value={externaluserName}
                                                        onChange={e => setexternaluserName(e.target.value)}
                                                        required/>
                                                    <label for="userEmail" className="form__label">Name</label>

                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form__group field" id="email">
                                                    <input
                                                        type="input"
                                                        className="form__field"
                                                        placeholder="Name"
                                                        name="userEmail"
                                                        id='outsideremail3'
                                                        value={externaluserEmail}
                                                        onChange={e => setexternaluserEmail(e.target.value)}
                                                        required/>
                                                    <label for="userEmail" className="form__label">Email</label>

                                                </div>
                                            </div>
                                            <div className="col-sm-12">

                                                <div className="form__group field" id="email">
                                                    <input
                                                        type="input"
                                                        className="form__field"
                                                        placeholder="Name"
                                                        name="userEmail"
                                                        id='outsidersubject3'
                                                        value={externaluserSubject}
                                                        onChange={e => setexternaluserSubject(e.target.value)}
                                                        required/>
                                                    <label for="userEmail" className="form__label">Subject</label>

                                                </div>

                                            </div>
                                            <div className="col-sm-12">

                                                <div className="form__group field" id="email">
                                                    <textarea
                                                        type="input"
                                                        className="form__field"
                                                        placeholder="Name"
                                                        name="userEmail"
                                                        id='outsidermessage3'
                                                        value={externaluserMessage}
                                                        onChange={e => setexternaluserMessage(e.target.value)}
                                                        required></textarea>
                                                    <label for="userEmail" className="form__label">Description</label>

                                                </div>

                                            </div>

                                        </div>
                                        <div className="container-fluid">
                                            <div className="row">

                                                <br/><br/>
                                                <div className="col-md-12 text-center">
                                                    <button className="btn-enter" type="button" onClick={e=>sendMessage(e)}>Send</button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal">
                    <h4>Security Check</h4>

                    <ReCAPTCHA
                        sitekey="6LfZuqobAAAAAMPv1wb330DHuckGGNfUSpdwRtRR"
                        onChange={onChange}/>
                    <button onClick={closeModal}>close</button>

                </Modal>
            </div>
        </div>
    )
}
export default Maindiv