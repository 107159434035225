import React, {useState, useCallback, useEffect} from 'react';
import firebase from 'firebase'
import './membership.css'
import 'firebase/database'
import config from '../firebasesdk/config'
import {useListVals, useList, useObjectVal} from 'react-firebase-hooks/database';
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();
function Membership() {
    const [link,loadinglink ] = useListVals(db.ref('UserAccount/'+ process.env.REACT_APP_USER+'/ProfessionalMembership'));
   
    return (
        <div>
         
             <div className="row">

             
         </div>
          {link && link.map(msg => <MembershipList group={msg}/>)}
        
          
        </div>
    )
}

function MembershipList(props) {
  const {Image,proforg,proftitle,profresNo} = props.group
 return(<>
 <div className="container membership">
     <div className="col-sm-3 col-xs-12 exImg">
     {Image ? <div><center><img src={Image} width="100%" alt="logo"/></center></div> : <div><center><img src="/image/portfolio.svg" width="80%" alt="logo"/></center></div>}
      </div>
      <div className="col-sm-9 col-xs-12">
      <h5><b>{proforg}</b></h5>
      {proftitle ? <div><p>{proftitle}</p></div> : <div></div>}
      {profresNo ? <div><p>Member-Id : {profresNo}</p></div> : <div></div>}
      </div>
      
      </div>
      </>)
}
export default Membership;