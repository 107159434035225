import React from 'react';
import firebase from 'firebase'
import '../Membership/membership.css'
import 'firebase/database'
import config from '../firebasesdk/config'
import {useListVals} from 'react-firebase-hooks/database';
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();
function Membership() {
    const [link ] = useListVals(db.ref('UserAccount/'+ process.env.REACT_APP_USER+'/Achivement/Award'));
   
    return (
        <div>
         
          {link && link.map(msg => <MembershipList group={msg}/>)}
        
          
        </div>
    )
}

function MembershipList(props) {
 const {Image,
    achivorg,achivdate,achivtitle,achivDesc} = props.group
    return(<>
    <div className="container membership">
                  <div class="col-sm-3 col-xs-12 exImg">
                                    {Image
                                        ? <div><center><img
                                                    src={Image}
                                                    width="100%"
                                                    alt="logo"/></center>
                                            </div>
                                        : <div>
                                            <center><img src="/image/school.svg" width="80%" alt="logo"/></center>
                                        </div>}
                                </div>
                                <div class="col-sm-9 col-xs-12">
                                    <h5>
                                        <b>{achivtitle}</b>
                                    </h5>
                                    <p>{achivorg}</p>
                                    <p>
                                        <span><img src="/image/event_available-white-36dp.svg" alt="logo"/></span>
                                        {achivdate}
                                    </p>

                                    {achivDesc
                                        ? <div>
                                                <p>{achivDesc}
                                                    <br></br>
                                                </p>

                                            </div>
                                        : <div></div>}
                                        
                                </div>   </div>
         </>)
}
export default Membership;