import React from 'react';
import firebase from 'firebase'
import "../Education/education.css"
import 'firebase/database'
import config from '../firebasesdk/config'
import {useListVals} from 'react-firebase-hooks/database';
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();
function Experience() {
    const [edu] = useListVals(db.ref('UserAccount/'+ process.env.REACT_APP_USER+'/WorkExperience'));
    edu.sort(function (a, b) {
        return parseFloat(b.EmpstartDate) - parseFloat(a.EmpstartDate);
    });
    return (
        <div>
            <ul className="cbp_tmtimeline" id="ed">
                {edu && edu.map(msg => <ExperienceList group={msg}/>)}

            </ul>
        </div>
    )
}

function ExperienceList(props) {

    var {EmpDes,
        Empcompany,
        EmpendDate,
        Emplocation,
        EmpstartDate,Emptitle,
        empType,
        Image,id} = props.group
    
return ( <><li> 
    <time className="cbp_tmtime">
       <div>
            <span>{EmpstartDate}-<br/>{EmpendDate}
            </span>
        </div>

       </time><div className = "cbp_tmicon cbp_tmicon-phone " >
       {Image ? <div>
                <center><img
                    src={Image}
                    width="80%"
                    alt="logo"/></center>
             </div>
           : <div></div> } 
         </div>
      <div className="cbp_tmlabel">
        <h2>{Emptitle}</h2 > 
        <p>{Empcompany}</p>
        {Emplocation ? <div>
                <p> {Emplocation}</p>
             </div>
          : <div></div>
         }
        {empType ? <div>
                <p>Emp. Type:  {empType}</p>
             </div>
          : <div></div>
         }
         {EmpDes ? <>
     <button className="btn btn-default" style={{"outline" : "none"}} data-toggle="collapse" data-target={"#"+id}>More <span className="caret"></span></button>
     <div id={id} className="collapse">
     <p>Description:  {EmpDes}</p>
     
     </div> </>:<></>}
    </div>
 </li> </>)
}
export default Experience;