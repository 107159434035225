import React from 'react';
import firebase from 'firebase'
import "./education.css"
import 'firebase/database'
import parse from 'html-react-parser';
import config from '../firebasesdk/config'
import {useListVals} from 'react-firebase-hooks/database';
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();
function Education() {
    const [edu] = useListVals(db.ref('UserAccount/'+ process.env.REACT_APP_USER+'/Education'));
    edu.sort(function (a, b) {
        return parseFloat(b.startDate) - parseFloat(a.startDate);
    });
    return (
        <div>
            <ul className="cbp_tmtimeline" id="ed">
                {edu && edu.map(msg => <EducationList group={msg}/>)}

            </ul>
        </div>
    )
}

function EducationList(props) {
    var {Board,
        branchDegreename,
        deptClassname,
        schoolCollegename,
        endDate,gradeName,
        Image,
        startDate,projecttitle ,
        titleDes , titleName ,supervisor,id
         } = props.group
    
return ( <><li> 
    <time className="cbp_tmtime">
       <div>
            <span>{startDate} - {endDate}
            </span>
        </div>

       <span>{branchDegreename}</span>
       </time><div className = "cbp_tmicon cbp_tmicon-phone ikg234234" >
           {Image ? <div>
                <center><img
                    src={Image}
                    width="100%"
                    alt="logo"/></center>
             </div>
           : <div></div> } 
         </div>
      <div className="cbp_tmlabel">
        <h2>{schoolCollegename}</h2 > 
        <p>{deptClassname}</p>
       {gradeName ? <div>
                <p>Grade: {gradeName}</p>
             </div>
          : <div></div>
         }

    

{projecttitle ? <>
     <button className="btn btn-default" style={{"outline" : "none"}} data-toggle="collapse" data-target={"#"+id}>More <span className="caret"></span></button>
     <div id={id} className="collapse">
         <br/>
         <p>{projecttitle} : <br/><b>{titleName}</b></p>
     {titleDes ? <p>Description :<br/> <b>{parse(titleDes.replaceAll('\n','<br/>'))}</b></p> : <></>}
           {supervisor ? <p>Supervisor :<br/> <b>{parse(supervisor.replaceAll('\n','<br/>'))}</b></p> : <></>}
     </div> </>:<></>}
</div>
 </li> </>)
}
export default Education;