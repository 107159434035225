import React, {useState, useCallback, useEffect} from 'react';
import firebase from 'firebase'
import "../Education/education.css"
import 'firebase/database'
import parse from 'html-react-parser';
import config from '../firebasesdk/config'
import {useListVals, useList, useObjectVal} from 'react-firebase-hooks/database';
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();
function Project() {
    const [edu,
        loading,
        error] = useListVals(db.ref('UserAccount/'+ process.env.REACT_APP_USER+'/Project'));
        edu.sort(function (a, b) {
            return parseFloat(b.projendDate) - parseFloat(a.projendDate);
        });
        return (
        <div>
            <ul className="cbp_tmtimeline" id="ed">
                {edu && edu.map(msg => <ProjectList group={msg}/>)}

            </ul>
        </div>
    )
}

function ProjectList(props) {
 

    var {Associatedwith,id,
        projectDes,
        projendDate,
        projectrole,
        projstartDate,projectSupervisor,
        projectUrl,
        projectlocation,projectname} = props.group
    
return ( <><li> 
    <time className="cbp_tmtime">
       <div>
            <span>{projstartDate} - {projendDate}
            </span>
        </div>

       </time><div className = "cbp_tmicon cbp_tmicon-phone projecticon" >
        
         </div>
      <div className="cbp_tmlabel">
         {projectUrl ?<a href={projectUrl}> <h2>{projectname}</h2 > </a> :  <h2>{projectname}</h2 >  } 
       
        <p>{Associatedwith}</p>
       {projectlocation ? <div>
                <p> {projectlocation}</p>
             </div>
          : <div></div>
         }
             {projectDes || projectSupervisor ? <>
     <button className="btn btn-default" style={{"outline" : "none"}} data-toggle="collapse" data-target={"#"+id}>More <span className="caret"></span></button>
     <div id={id} className="collapse">
     {projectSupervisor ? <p>Supervisor :<br/> <b>{parse(projectSupervisor.replaceAll('\n','<br/>'))}</b></p> : <></>}
           {projectDes ? <p>Description :<br/> <b>{parse(projectDes.replaceAll('\n','<br/>'))}</b></p> : <></>}
     </div> </>:<></>}
    </div>
 </li> </>)
}
export default Project;