import React, {useState, useCallback, useEffect} from 'react';
import firebase from 'firebase'
import './skill.css';
import 'firebase/database'
import config from '../firebasesdk/config'
import { CircularProgressbar,buildStyles  } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {useListVals, useList, useObjectVal} from 'react-firebase-hooks/database';
if (!firebase.apps.length) {
    firebase.initializeApp(config);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.database();
function Skill() {
    const [edu,
        loading,
        error] = useListVals(db.ref('UserAccount/'+ process.env.REACT_APP_USER+'/Skill/Programming+Skill'));
        
    return (
        <div>
            <center>
          <div className="skilldiv">
          {edu && edu.map(msg => <SkillList group={msg}/>)}
          </div>
          </center>

          
        </div>
    )
}

function SkillList(props) {
   const{Skill,SkillRating} = props.group;

        return(<>  {/*<label>{Skill}</label>
       <div className="progress" >
        <div className="progress-bar" role="progressbar" aria-valuenow={ SkillRating} aria-valuemin="0" aria-valuemax="10" 
        style={{width:SkillRating+'0%'}}> </div>

        </div>*/}
        <div className='col-md-4 col-xs-6'>
        <div className="skillval skillcolort3" style={{margin:'20px'}}>
        <CircularProgressbar value={SkillRating*10} text={Skill}styles={buildStyles({
    strokeLinecap: 'butt',
    textSize: '16px',
    pathTransitionDuration: 0.5,
    pathColor: `#248471`,
    trailColor: '#e7e7e7',
  
  })} />
          </div></div>
        </>)
}
export default Skill;