import React from 'react';
import Maindiv from './mainDiv/Maindiv'
import "./App.css";
function App () {
     return (
       <div> 
        <Maindiv/> 
       </div>
     );
   
 }
 
export default App;